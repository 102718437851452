import React, { useState, useEffect, useContext } from "react";
import "./LiveSessionView.css";
import ColorUI from "../../ColorUI/ColorUI";
import ControllerContext from "../../../Contexts/ControllerContexts";
import { ApplicationName, CurrentUserDataModel } from "../../../Models/Models";
import { userprofile_storage_path } from "../../../fb/dbMethods";
import { getDownloadURL, ref } from "firebase/storage";
import { db, storage } from "../../../fb/fbSetup";
import { doc, getDoc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import {
    freeTurnServers, 
    stunServers,
    LiveSessionModel,
    // Methods
    ____CheckForAnyOpenChatSessoions,
    // Models
    _CallerModel,
    LiveSessionRole,
    _AnswererModel,
    customRTCSessionDescription,
    customRTCSessionDescription_Type,
    // consts
    Live_Session_Storage_Ref,
    nestedOfferCandidatePath,
    nestedAnswerCandidatePath,
} from "./LiveSessionController/LiveSessionController";
import UserContext from "../../../Contexts/UserContext";
import uuidGenerator from "../../../../SharedItems/UUIDGenerator";
import StartSessionPrompt from "../StartSessionPrompt/StartSessionPrompt";

interface PromptsListItemModel {
    id: string;
    prompt: string;
    used: boolean;
}

const LiveSessionView = () => {
    const { showLiveSessionView, toggleLiveSessionView } = useContext(ControllerContext);
    const { userData } = useContext(UserContext);
    const [ otherUserID, setOtherUserID ] = useState<string>("");
    const [ renderView, setRenderView ] = useState<boolean>(false);
    const [ animateView, setAnimateView ] = useState<boolean>(true);
    useEffect(() => {
        if(showLiveSessionView) {
            setRenderView(showLiveSessionView);
            setTimeout(() => {
                setAnimateView(showLiveSessionView);
            }, 150);
        } else {
            setAnimateView(showLiveSessionView);
            setTimeout(() => {
                setRenderView(showLiveSessionView);
            }, 400);
        }
    }, [ showLiveSessionView ]);

    // Test Video Call Setup:
    // Test Video Call Setup:
    // Test Video Call Setup:
    // Test Video Call Setup:
    const servers = {
        'iceServers': [
            {
                'urls': 'stun:stun.l.google.com:19302'
            },
            ...dataExamples,
        ]
    };
    // global state
    const [ pc, set_pc ] = useState<RTCPeerConnection>(new RTCPeerConnection(servers));
    const [ statHasChanged, setStateHasChanged ] = useState(false);
    const [ webCamVideo, setWebCamVideo ] = useState(document.querySelector(".LiveSession .user-container.user-two video"));
    const [ remoteVideo, setRemoteVideo ] = useState(document.querySelector(".LiveSession .user-container.user-two video"));

    let metered_TempAPIKey = "00adb3ed4f498719f183f34f6bb9d33cde46"
    let metered_app_name = "bwp"
    useEffect(() => {
        if (showLiveSessionView === true) {
            // fetch(`https://${metered_app_name}.metered.live/api/v1/turn/credentials?apiKey=${metered_TempAPIKey}`)
            // .then( sucess => {
                setTimeout(() => {
                    set_pc(_=> { return new RTCPeerConnection(servers); });
                    setWebCamVideo(_=> { return document.querySelector(".LiveSession .user-container.user-two video"); });
                    setRemoteVideo(_=> { return document.querySelector(".LiveSession .user-container.user-one video"); });
                    setStateHasChanged( _=> { return true; }); 
                }, 2000);
            // })
        }
    }, [ showLiveSessionView ]);

    useEffect(() => {
        if (statHasChanged && showLiveSessionView) {
            getUserMedia()
        }
    }, [ statHasChanged, showLiveSessionView ]);

    const [ localStream, setLocalStream ] = useState<MediaStream | undefined>(undefined);
    const [ remoteStream, setRemoteStream ] = useState<MediaStream | undefined>(undefined);
    function getUserMedia() {
        navigator.mediaDevices.getUserMedia({ video: true, audio: true })
        .then( stream => {
            setRemoteStream(() => { return new MediaStream(); });
            setLocalStream(() => { return stream; });
        })
        .catch(error => {
        })
    }

    const [showPrompt, setShowPrompt] = useState(false);  
    const AskIfUserWantsToStartSession = () => {
      setShowPrompt(true);
    };
  
    const handleConfirmSession = () => {
      setShowPrompt(false);
      checkForAvailbleSessions();
    };
  
    const handleCancelSession = () => {
      setShowPrompt(false);
      // Handle cancellation (e.g., stop streams, close view)
    };

    useEffect(() => {
        makeStreamsAvaibleOnPeerConnection();
    }, [ localStream ])

    function makeStreamsAvaibleOnPeerConnection() {
        if (localStream && webCamVideo) {
            localStream.getTracks().forEach(track => {
                pc.addTrack(track, localStream);
            })
            
            pc.ontrack = event => {
                if (event.streams.length > 0) {
                    event.streams[0].getTracks().forEach(track => {
                        remoteStream.addTrack(track);
                    })
                }
            }
            // @ts-ignore
            webCamVideo.srcObject = localStream;
            // @ts-ignore
            remoteVideo.srcObject = remoteStream ;
            //
            // checkForAvailbleSessions();
            AskIfUserWantsToStartSession();
        }
    }
    // Check if there are any avaible session open
    function checkForAvailbleSessions() {
        const userID =  userData.userID;
        ____CheckForAnyOpenChatSessoions(
            userID,
        (returnedErrorFree, foundASession, session) => {
            if (returnedErrorFree) {
                if (foundASession && session) {
                    answerCall(session);
                } else {
                    createCall();
                }
            }
        })
    }
    //
    //time preparing for an answer 8:50
    let answerListener;
    let answerICEListener;
    async function createCall() {
        const documentID = uuidGenerator(); 
        const callDoc = doc(db, `${Live_Session_Storage_Ref}/${documentID}`);

        // Listen for any ice candidates:
        pc.onicecandidate = event => {
            if (event.candidate) {
                const offerCandidates = doc(db, `${Live_Session_Storage_Ref}/${documentID}/${ nestedOfferCandidatePath}/${nestedOfferCandidatePath}`);
                setDoc(offerCandidates, event.candidate.toJSON())
                .then(_ => {
                })
                .catch(error => {
                })
            }
        }
        // create offer
        const offerDescription = await pc.createOffer();
        // set local descript on peer connection to the offer description
        await pc.setLocalDescription(offerDescription);
        // store offer description on db
        const offer : customRTCSessionDescription = {
            sdp: offerDescription.sdp,
            type: offerDescription.type as customRTCSessionDescription_Type
        };
        const sessionObj: LiveSessionModel = {
            createdBy: userData.userID,
            timeOfSessionCreation: new Date(),
            SessionID: uuidGenerator(),
            documentID: documentID,
            Caller: {
                userID: userData.userID,
                //
                sessionDescriptionProtocol: offer,
                //
                role: LiveSessionRole.caller,
            },
            // Answerer: undefined,
            isPaired: false,
            isActive: true,
        }
    
        await setDoc(callDoc, sessionObj)
        .then(() => {
            const ICE_answer_str_ref = `${ Live_Session_Storage_Ref }/${documentID}/${ nestedAnswerCandidatePath }/${ nestedAnswerCandidatePath }`;
            const ICE_AnswerDoc = doc(db, ICE_answer_str_ref);
            //Add A temporary answer ICE Doc
            setDoc(ICE_AnswerDoc, { _obj_holder_item_shouldnt_exist_when_updated_by_answerer : true })
            .then(() => {
                // Listen for an answer
                answerListener = onSnapshot(callDoc, (snapshot) => {
                    let data = snapshot.data() as LiveSessionModel;
                    if (!pc.currentRemoteDescription && data.Answerer && data.Answerer.sessionDescriptionProtocol) {
                        const sdp = data.Answerer.sessionDescriptionProtocol;
                        pc.setRemoteDescription(sdp)
                        setOtherUserID(_=> { return data.Answerer.userID; })
                    }
                });

                answerICEListener = onSnapshot(ICE_AnswerDoc, (snapshot) => {
                    if (snapshot.data()._obj_holder_item_shouldnt_exist_when_updated_by_answerer) {
                        // Do no work  because this is a temorary object...
                    } else {
                        let data = snapshot.data() as RTCIceCandidateInit;
                        if (data) {
                            let candidate = new RTCIceCandidate(data);
                            pc.addIceCandidate(candidate);
                        } else {
                            let candidate = new RTCIceCandidate(snapshot.data());
                            pc.addIceCandidate(candidate);
                        }
                    }
                })
            })
        })
        .catch(error => {
        });
    }

    let offerICEListener;
    async function answerCall(session: LiveSessionModel) {
        const docID = session.documentID;
        const callDoc = doc(db, `${Live_Session_Storage_Ref}/${docID}`);
        const answerCandidates = doc(db,`${ Live_Session_Storage_Ref }/${docID}/${ nestedAnswerCandidatePath }/${ nestedAnswerCandidatePath }`);
        pc.onicecandidate = event => {
            if (event.candidate) {
                const offerCandidates = doc(db, `${Live_Session_Storage_Ref}/${docID}/${nestedAnswerCandidatePath}/${nestedAnswerCandidatePath}`);
                setDoc(offerCandidates, event.candidate.toJSON())
                .then(_ => {
                })
                .catch(error => {
                })
            }
        }

        await getDoc(callDoc)
        .then(async (document) => {
            let data = document.data() as LiveSessionModel;
            if (data) {
                let sessionDescription = new RTCSessionDescription(data.Caller.sessionDescriptionProtocol);
                pc.setRemoteDescription(sessionDescription);
                setOtherUserID(_=> { return data.Caller.userID; })
                const answerDescription = await pc.createAnswer();
                await pc.setLocalDescription(answerDescription);

                const answer : customRTCSessionDescription = {
                    sdp: answerDescription.sdp,
                    type: answerDescription.type as customRTCSessionDescription_Type
                };

                const answererObj = { Answerer: { "userID": userData.userID, "sessionDescriptionProtocol": answer, "role": LiveSessionRole.answerer, }, isPaired: true, }
                await updateDoc(callDoc, answererObj)
                .then(() => {
                    const ICE_offer_str_ref = `${ Live_Session_Storage_Ref }/${session.documentID}/${ nestedOfferCandidatePath }/${ nestedOfferCandidatePath }`;
                    const ICE_OfferDoc = doc(db, ICE_offer_str_ref);
                    offerICEListener = onSnapshot(ICE_OfferDoc, (snapshot) => {
                        let candidate = new RTCIceCandidate(snapshot.data());
                        pc.addIceCandidate(candidate);
                    })
                })
                .catch(error => {
                }) 
            }
        })
        .catch(error => {
        })
    }

    
    return (
        <>
            { renderView &&
                <div className="LiveSession-main-container" style={{ top: animateView ? "0" : "200vh", backdropFilter: "blur(12px)", backgroundColor: animateView ? "black" : "transparent" }}>
                    <div className="LiveSession">
                        <div className="background-view">
                            <ColorUI/>
                        </div>
                        <div className="livesession-contentview">
                            <div className="livesession-vstack content-container">
                                <div className="livesession-hstack title-and-back">
                                    <button className="close-button" onClick={() => { 
                                        if (localStream) {
                                            localStream.getTracks().forEach(track => {
                                                track.stop();
                                            });
                                        }
                                        if (pc) {
                                            pc.close();
                                        }
                                        setLocalStream(undefined);
                                        setRemoteStream(undefined);
                                        set_pc(new RTCPeerConnection(servers));
                                        toggleLiveSessionView(false);
                                        
                                        // hangupAction();
                                        toggleLiveSessionView(false);
                                    }} >x</button>
                                    <h1 className="app-name">{ ApplicationName}</h1>
                                    <h1 className="view-title">{` Live Chat Session` }</h1>
                                    <h4 className="description-container"> Here to allow you to talk to one of our incredible community members</h4>
                                    <button className="report-button">report</button>
                                </div>
                                <div className="livesession-hstack content-container">
                                    <div className="video-container">
                                        <VideoUserOneView userID={ 
                                            otherUserID
                                            //"mp699DW1KWOM0yMWbQETcATb8cJ3"
                                         }/>
                                        <VideoUserTwoView userID={ userData.userID }/>
                                    </div>
                                    <PrompsView/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {   showPrompt && (
                            <StartSessionPrompt
                            onConfirm={handleConfirmSession}
                            onCancel={handleCancelSession}
                            />
                        )
                    }
                </div>
            }
        </>
    );
}

interface VideViewProps { userID: string; }
const VideoUserOneView = ( { userID }: VideViewProps ) => {
    const [ imageSrc, setImageSrc ] = useState< string | undefined >(undefined);
    useEffect(()=>{ 
        if (userID.trim() !== "") {
            getCommentUserProfileImage();
        }
     },[ userID ]);
     
    async function getCommentUserProfileImage() {
        const userProfileStorageRef = ref(storage, `${ userprofile_storage_path }/${userID}.jpg`);
        getDownloadURL(userProfileStorageRef)
        .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            // Adding the URL to the message options
            setImageSrc(url);
        })
        .catch((error) => {
            // Handle any errors
        });
    }

    return (
        <div className="user-container user-one">
            {/*  */}
            <div className="video-profile-image-container">
                { imageSrc && <img loading={ "lazy" } src={ imageSrc }/>}
                <div className="image-cover"/>
            </div>
            {/*  */}
            <video id="video" autoPlay={ true } playsInline={ true }/>
            <UserInfoContainer userID={ userID } imageSrc={ imageSrc }/>
        </div>
    )
}

const VideoUserTwoView = ( { userID }: VideViewProps ) => {
    const [ imageSrc, setImageSrc ] = useState< string | undefined >(undefined);
    useEffect(()=>{  getCommentUserProfileImage(); },[]);
    async function getCommentUserProfileImage() {
        const userProfileStorageRef = ref(storage, `${ userprofile_storage_path }/${userID}.jpg`);
        getDownloadURL(userProfileStorageRef)
        .then((url) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                const blob = xhr.response;
            };
            xhr.open('GET', url);
            xhr.send();
            // Adding the URL to the message options
            setImageSrc(url);
        })
        .catch((error) => {
            // Handle any errors
        });
    }

    return (
        <div className="user-container user-two">
            {/*  */}
            <div className="video-profile-image-container">
                { imageSrc && <img loading={ "lazy" } src={ imageSrc }/>}
                <div className="image-cover"/>
            </div>
            {/*  */}
            <video id="video" autoPlay={ true } playsInline={ true } muted={ true }/>
            <UserInfoContainer userID={ userID } imageSrc={ imageSrc }/>
        </div>
    )
}

interface UserInfoContainerProps { userID: string; imageSrc: string | undefined }
const UserInfoContainer = ( { userID, imageSrc }: UserInfoContainerProps ) => {
	const [ ContentUser, setContentUser ] = useState< CurrentUserDataModel | undefined >(undefined);
    useEffect(()=>{
        if (userID.trim() !== "") {
            getCommentUserData();
        }
    },[ userID ]);
    async function getCommentUserData(){
        const userDataRef = doc(db, `${ userprofile_storage_path }/${ userID }`);
        await getDoc(userDataRef)
        .then(document => {
            const data = document.data() as CurrentUserDataModel;
            if (data) {
                setContentUser(_=> { return data; });
            }
        })
        .catch( err => {
        });
    }

    return (
        <div className="user-info-container">
            <div className="user-profile-image-container">
                { imageSrc && <img loading={ "lazy" } src={ imageSrc }/>}
                <div className="image-cover"/>
            </div>
            { ContentUser &&
                <div className="username-nickname">
                    <label className="first-last">{`${ ContentUser.firstName } ${ ContentUser.lastName }`}</label>
                    { ContentUser.customUserName && <label className="nickname" >{ `@${ ContentUser.customUserName }`}</label>}
                </div>
            }
        </div>
    )
}

const PrompsView = () => {
    const [ allPrompsList, setAllPrompsList ] = useState<string[]>(shuffle(dummyQuestionExamples));
    const [ questionIndex, setQuestionIndex ] = useState<number>(0);
    

    function changeQuestion(gotToNext: boolean) {
        if (gotToNext === true && questionIndex < (allPrompsList.length - 1)) {
            setQuestionIndex(i => { return i + 1; });
        } else if ( questionIndex > 0 )  {
            setQuestionIndex(i => { return i - 1;});
        }
    }
    return (
        <div className="prompts">
            <h1 className="prompts-telos">Generated Talking Points:</h1>
            <div> 
                <h3 className="prompts-futher-description">These are some ice-breakers to lean on for the conversation if it feels a bit challenging.</h3>
                <h3 className="prompts-futher-description" style={{ marginTop: "0" }}>Don't be scared to use them. Many community members use them to reduce the social friction.</h3>
            </div>
            <div className="prompt-items-container hideScrollIndicator">
                    <h2 className="question-item">{ allPrompsList[ questionIndex ] }</h2>
            </div>
            <div className="prompts-action-buttons">
                <button className="prev" onClick={() => { changeQuestion(false) }}>previous</button>
                <div className="index-count-container">
                    <label>{`${ questionIndex + 1 } / ${ allPrompsList.length }`}</label>
                </div>
                <button className="next" onClick={() => { changeQuestion(true) }}>next</button>
            </div>
        </div>
    )
}

export default LiveSessionView;


function shuffle(array) {
    let currentIndex = array.length,  randomIndex;
  
    // While there remain elements to shuffle.
    while (currentIndex > 0) {
  
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  
    return array;
  }

const physics_questions = [
    "What is dark matter, and how does it affect the universe's structure?",
    "How do quantum entanglement and non-locality challenge our understanding of the nature of reality?",
    "Can the theory of everything, unifying all fundamental forces, be achieved?",
    "What is the significance of the Higgs boson in the Standard Model of particle physics?",
    "How does time dilation work in the theory of relativity, and what are its practical implications?",
    "What is the nature of black holes, and can we ever observe the inside of one?",
    "How do the laws of thermodynamics apply to the behavior of matter and energy in the universe?",
    "What is the double-slit experiment, and what does it reveal about the nature of particles and waves?",
    "How are the principles of quantum mechanics used in modern technology, such as quantum computing?",
    "What are the leading theories about the ultimate fate of the universe, and what evidence supports them?"
]

const philosophy_questions = [
    "What is the nature of consciousness, and can it be explained through materialism or dualism?",
    "Is morality objective, or is it a product of cultural and individual perspectives?",
    "What is the nature of free will, and how does it relate to determinism?",
    "Can we know anything for certain, and what are the limits of human knowledge?",
    "What is the relationship between language and thought, and how does it shape our understanding of the world?",
    "What is the meaning of life, and can it be derived from philosophy or existentialism?",
    "How do various philosophical traditions, such as existentialism and utilitarianism, inform ethical decision-making?",
    "What is the problem of evil, and how do philosophers grapple with the existence of suffering in a world created by a benevolent deity?",
    "Is reality objective, or is it a construct of the mind, as explored in solipsism and idealism?",
    "How do different philosophical theories of time, such as presentism and eternalism, influence our perception of reality?"
]

const technology_questions = [
    "What are the potential benefits and risks of artificial intelligence, and how can they be mitigated?",
    "How will the integration of 5G technology impact various industries and daily life?",
    "What are the ethical considerations surrounding gene editing and CRISPR technology in humans?",
    "How can blockchain technology revolutionize industries beyond cryptocurrencies?",
    "What is the future of renewable energy sources and their impact on environmental sustainability?",
    "How do quantum computers differ from classical computers, and what problems can they solve more efficiently?",
    "What are the potential consequences of advanced surveillance technologies on privacy and civil liberties?",
    "How will the Internet of Things (IoT) change the way we interact with our environment and devices?",
    "How can biotechnology and nanotechnology enhance medical treatments and diagnostics?",
    "What are the challenges and opportunities in developing and implementing self-driving cars and autonomous transportation systems?"
]

const funny_questions = [
    "If you were a vegetable, what vegetable would you be and why?",
    "If you could have any superpower, but it only worked when you were doing the Macarena, what power would you choose?",
    "What's the most embarrassing song on your playlist that you secretly love?",
    "If you could replace your hands with any object for a day, what would you choose?",
    "If animals could talk, which one do you think would be the funniest to have a conversation with?",
    "What's your go-to dance move when nobody's watching?",
    "If you could only eat one food for the rest of your life, what would it be, and how soon do you think you'd get tired of it?",
    "If your life had a theme song that played every time you walked into a room, what would it be?",
    "What's the silliest nickname you've ever been given, and how did you get it?",
    "If you could time travel, but only for 30 seconds, where and when would you go?",
    "What would your autobiography be titled if it were a comedy?",
    "If you had to trade lives with an inanimate object for a day, what would you choose, and why?",
    "What's your signature dish in the kitchen, and how many fires has it caused?",
    "If you could have a conversation with any fictional character, who would it be and what would you talk about?",
    "If you could make one everyday activity an Olympic sport, what would it be, and how would you train for it?",
    "What's the most unusual talent you have that nobody knows about?",
    "If you could have any animal sidekick, which animal would you choose to accompany you in your daily life?",
    "What's the funniest meme or internet trend you've ever come across?",
    "If you were a character in a sitcom, what would your catchphrase be?",
    "If you could swap lives with any historical figure for a day, who would it be, and what would you do?"
]

const dummyQuestionExamples = [
    ...physics_questions,
    ...philosophy_questions,
    ...technology_questions,
    ...funny_questions,
]


// https://dashboard.metered.ca/
const dataExamples = [
    {"urls":"stun:stun.relay.metered.ca:80"},
    {"urls":"turn:a.relay.metered.ca:80","username":"7017cfecc84f61137d6aae20","credential":"IvJ7y0i3jk7MvwF7"},
    {"urls":"turn:a.relay.metered.ca:80?transport=tcp","username":"7017cfecc84f61137d6aae20","credential":"IvJ7y0i3jk7MvwF7"},
    {"urls":"turn:a.relay.metered.ca:443","username":"7017cfecc84f61137d6aae20","credential":"IvJ7y0i3jk7MvwF7"},
    {"urls":"turn:a.relay.metered.ca:443?transport=tcp","username":"7017cfecc84f61137d6aae20","credential":"IvJ7y0i3jk7MvwF7"}
]
